/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file QueueDetails.js
 * @author Prakash // on 24/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import { listQueueDetails, deleteQueue } from '../../../actions/Collider/ClusterAction'
import _ from 'lodash'
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, convertBytesTo, } from '../../../utils/utility'

import { CSVLink } from 'react-csv';

import QueueDetailsSidePanel from './QueueDetailsSidePanel'
import Search from '../../common/SearchWithHiddenInput'
import CreateUpdateQueuePanel from './CreateUpdateQueuePanel';
 import { Store as CommonNotification } from 'react-notifications-component';
import FilterSection from '../../common/FiltersSection';
import {Icon} from "@iconify/react";


const QueueDetails = () => {
    const clickOutside = useRef()
        
    const [state, setState] = useState({
        showLoading: true,

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 10,

        queueDetails: [],
        selectedQueueDetails: {},
        filteredQueueDetails: [],
        queueheaders: [],
        deleteModal: false,

        actionsAllowed: [],
        topSectionFilters: ['provider', 'project', 'account', 'region', 'cluster']
    })    

    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation();
	const currentPath = location.pathname;

    const providers = useSelector(state => state?.filters?.providers || false);
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].action
                    }
				} 
                // else {
				// 	if(row.action) {
				// 		actionsAllowed = row.action
				// 	}
				// }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],            
            callSearch: true,
            showLoading: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: '', selectedProjectTags: [], selectedAccount: [], selectedRegion: [], selectedClusters: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false, startRecord: 0,  currentPage: 1 }))
            
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                params.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== "All")
            }            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== "All")
            }            
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters.filter(e => e !== "All")
            }

            if(state.nextToken && state.nextToken.length) {
                params.next_token = state.nextToken
            }
            // params.size = 10
            if(state.selectedProvider) {
                dispatch(listQueueDetails(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results && response.results.length ? response.results : []

                            let totalResults = [] 
                            let startRecord = state.startRecord
                            let currentPage = state.currentPage 
                            if(state.nextToken && state.nextToken.length) {
                                totalResults = state.queueDetails &&  state.queueDetails.length ? state.queueDetails : []
                                startRecord = startRecord + state.perPage;
                                currentPage = currentPage+ 1
                            }

                            if(results && results.length) {
                                totalResults = totalResults.concat(results)
                            }
                            let totalPages = 1
                            if(totalResults.length > state.perPage) {
                                totalPages = Math.ceil(totalResults.length / state.perPage)
                            }
                            
                            setState(prevState => ({ ...prevState, queueDetails: totalResults, filteredQueueDetails: totalResults, detailsFormat: response && response.queue_details ? response.queue_details : [], showLoading: false, totalPages, startRecord, currentPage, nextToken: response.next_token, totalRecords: response.total }));
                        }
                })
            } else {
                setState(prevState => ({ ...prevState, queueDetails: [], filteredQueueDetails: [], showLoading: false }));
            }
        }
    }, [dispatch, state.callSearch, state.perPage, state.selectedAccount, state.queueDetails, state.selectedClusters, state.selectedProjectTags, state.selectedProvider, state.selectedRegion, state.currentPage, state.nextToken, state.startRecord])

    useEffect(() => {
        if(state.queueDetails && state.queueDetails.length) {
            let dataResult = state.queueDetails
            let headers = []
            Object.entries(dataResult[0]).forEach(([key, value]) => {
                if(typeof value === 'string') {
                    let headerRow = {}
                    headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                    headerRow.key = key
                    headers.push(headerRow)
                }
            })
    
            setState(prevState => ({ ...prevState, queueheaders: headers }))
        }

        //status graph of how many running days 
        state.queueDetails && state.queueDetails.forEach(row => {
            let donut = [{
                item_count: row.number_of_days ? row.number_of_days : 0,
                item_name: 'Days'
            }]
            let donut_total_count = row.number_of_days ? row.number_of_days : 0
            // state.statusSection.trend && state.statusSection.trend.length && state.statusSection.trend[0].total && Object.entries(state.statusSection.trend[0].total).forEach(([key, value]) => {
            //     let donutRow = {}
            //     donutRow.item_count = value
            //     donutRow.item_name = key
            //     donut.push(donutRow)
            //     donut_total_count += value 
            // })
            donut = _.orderBy(donut, ['item_count'], ['desc'])
    
            let statusDonutData = {
                'items': donut,
                'label': 'Days',
                'total_count': donut_total_count
            }
    
            setState(prevState => ({ ...prevState, ['statusDonutData_'+row.queue_name]: statusDonutData }))
        })
    }, [state.queueDetails])

    useEffect(() => {
        if(state.refreStatusDontGraph) {
            setState(prevState => ({ ...prevState, refreStatusDontGraph: false }))
        }
    }, [state.refreStatusDontGraph])

    useEffect(() => {
        if(state.selectedQueueDetails && Object.entries(state.selectedQueueDetails).length)
        setState(prevState => ({ ...prevState, showQueueDetails: true }))
    }, [state.selectedQueueDetails])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    useEffect(() => {
        if(state.pageMode) {
            setState(prevState => ({ ...prevState, showCreateUpdateQueuePanel: true }))
        }
    }, [state.pageMode])

    
	
	const handleInputChange = (label, value) => {
        setState(prevState => ({ ...prevState, [label]: value, deleteConfirmMessage: '' }))
	}

    const deleteQueueFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let item = state.selectedDeleteItem 
        let params = {}
        if(item && item.asset_id) {
            params.asset_id = item.asset_id
        } else {
            params.provider = item.provider
            params.account_id = item.account_id
            params.region = item.region
            params.cluster_name = item.cluster_name
            params.queue_name = item.queue_name
        }
        if(state.queue_name && state.queue_name === state.selectedDeleteItem.queue_name) {
            dispatch(deleteQueue(params))
                .then((response) => {
                    if(response) {
                        let messageType = 'danger'
                        let message = response && response.message ? response.message : "Erro in deleting"
    
                        if(response.status) {
                            messageType = 'success'
                            setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false }))
                            // toggle()
                            setTimeout(() => { setState(prevState => ({ ...prevState, callSearch: true, showDeleteLoading: false }))}, 1000)
                        } else {
                            setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                        }
    
    
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                    }
                })
        } else {
            if(!state.queue_name || state.queue_name === '') {
                setState(prevState => ({ ...prevState, deleteConfirmMessage: "Please enter queue name", showDeleteLoading: false }))
            } else if(state.queue_name !== item.queue_name) {
                setState(prevState => ({ ...prevState, deleteConfirmMessage: "Entered Queue name does not match selected queue name", showDeleteLoading: false }))
            }
        }

    }

    useEffect(() => {
        if(state.callDelete && state.selectedDeleteItem && Object.entries(state.selectedDeleteItem).length) {
            setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, callDelete: false }))
        }
    }, [state.callDelete, state.selectedDeleteItem, state.deleteModal])

    const handleChildClick = (event, type, dropdownType, section) => {
        
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            
            {state.showCreateUpdateQueuePanel ? 
                <CreateUpdateQueuePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showCreateUpdateQueuePanel: false, pageMode: '', editQueueDetails: {}, callSearch: true }))}
                    selectedQueueDetails={state.editQueueDetails}
                    pageMode={state.pageMode}
                />
            : null}
            {state.showQueueDetails ? 
                <QueueDetailsSidePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showQueueDetails: false, selectedQueueDetails: {} }))}
                    selectedQueueDetails={state.selectedQueueDetails}
                    detailsFormat={state.detailsFormat ? state.detailsFormat : []}
                />
            : null}
            
            
            <div className="bg-white w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-3 col-span-12 self-center xl:mt-4">
                        <p className="text-black mx-4 text-xl md:mt-0 mt-4 font-semibold">Queue Details</p>
                        <p className="mx-4 text-black">Consolidated view of queue details</p>
                    </div>
                    <div className="xl:col-span-9 col-span-12 items-center lg:mt-4 mt-6 lg:ml-4" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
                
            <div className='p-2 min-h-screen overflow-auto'>
                <div className='flex flex-wrap justify-between mb-2'>
                    <div className='lg:w-1/2 w-full self-center'>
                        <p className='mb-0 self-center text-black text-lg'>Queue List</p>
                        <p className='mb-0 self-center text-lightGray'>Showing {state.filteredQueueDetails && state.filteredQueueDetails.length} of total {state.queueDetails && state.queueDetails.length} queue(s)</p>
                    </div>
                    <div className='self-center lg:w-1/2 w-full'>
                        <div className='flex justify-end'>
                            {state.filteredQueueDetails && state.filteredQueueDetails.length ?
                                <CSVLink 
                                    data={state.filteredQueueDetails ? state.filteredQueueDetails : []} 
                                    headers={state.queueheaders ? state.queueheaders : []}
                                    filename={'queue-lists.csv'}
                                    className={'mr-2 self-center'}
                                    target='_blank'
                                >
                                    <Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
                                </CSVLink>
                            : null}
                            {state.queueDetails && state.queueDetails.length ?
                                <Search
                                    data={state.queueDetails ? state.queueDetails : []}
                                    applyTags={false}
                                    applyLiteDarkTags={true}
                                    topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
                                    searchClassName={'px-2'}
                                    searchIconColor={'text-black '}
                                    searchPlaceHolder={'Search....'}
                                    className={'bg-transparent text-black'}
                                    widthHeight={25}
                                    filteredData={(filteredQueueDetails) => {
                                        let totalPages = 1
                                        if(filteredQueueDetails && filteredQueueDetails.length > state.perPage) {
                                            totalPages = Math.ceil(filteredQueueDetails.length / state.perPage)
                                        }
                                        setState(prevState => ({ ...prevState, filteredQueueDetails, totalPages, startRecord: 0, currentPage: 1 }))
                                    }}
                                />
                            : null}
                            <button className={`btn bg-HalloweenOrange rounded-md px-2 py-1 text-white ml-2`} onClick={() => setState(prevState => ({ ...prevState, pageMode: 'Create' }))}>Create&nbsp;Queue</button>
                        </div>
                        
                        {state.totalRecords > state.perPage ?
                            <div className='flex justify-end text-black w-full mt-2'>
                                <span className='mx-3'>Page <strong>{state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)}</strong> </span>
                                <div className="pagination text-sm">
                                    <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                                    <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button> 
                                    {/* <span>
                                        Go to
                                        <input
                                            type='number'
                                            value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentUpcoming}
                                            onChange={e => {
                                                const page = e.target.value ? Number(e.target.value) : ""
                                                gotoPage(page)
                                            }}
                                            className="ml-1 inputClass"
                                        />
                                    </span> */}
                                    <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                                    <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                                </div>
                            </div>
                        : null}
                    </div>
                </div>
                {state.filteredQueueDetails && state.filteredQueueDetails.length ? 
                    state.filteredQueueDetails.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                        return(
                            <div className='flex flex-wrap bg-white rounded-md p-3 mb-2' key={"row_"+(state.startRecord + i)}> 
                                <div className="lg:w-1/4 md:1/3 sm:w-1/2 w-full pr-0">
                                    <div className='py-2'>
                                        <div className="flex">
                                            <div className="py-1 w-full">
                                                <div className='flex'>
                                                    <p className="b-block mb-0 mr-2">Queue</p>
                                                    <p className="mb-0 text-black">
                                                        { item.queue_name ? item.queue_name : ''}
                                                    </p>
                                                </div>
                                                <div className='flex flex-wrap text-lightGray'>
                                                    {item.nodes && item.nodes.length ?
                                                        <React.Fragment>
                                                        <span className='mr-2'>CPUs: {item.nodes[0].cpus ? item.nodes[0].cpus : ''}, </span>

                                                        <span className='mr-2'>Mem:
                                                            {item.nodes[0].memory ? 
                                                                convertBytesTo(item.nodes[0].memory, 'Megabytes', 'round', 'GB')
                                                                : ''
                                                            },
                                                        </span>
                                                        </React.Fragment>
                                                    : null}
                                                    {item.total_nodes ?
                                                        <span>Nodes: {item.total_nodes}</span>
                                                    : null} 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex">
                                            <div className="py-1 w-full">
                                                <p className="b-block mb-0">Cluster</p>
                                                <p className="mb-0 text-black">
                                                    { item.cluster_name ? item.cluster_name : ''}
                                                    {item.cluster_version ?
                                                        <span className={`mb-0 mr-2 text-lg text-black`}>
                                                            (v {item.cluster_version})
                                                        </span>
                                                    : null}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex pt-2">
                                            <div className="py-1 w-full">
                                                <p className="b-block mb-0">Resources</p>
                                                <p className="mb-0 text-black">
                                                    {item.account_id ?
                                                        <span>{' '+item.account_id}</span>
                                                    : null}
                                                    {item.region ?
                                                        <span>{' '+item.region}</span>
                                                    : null}
                                                    {item.resource_type ?
                                                        <span>{' '+item.resource_type}</span>
                                                    : null}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="lg:w-1/4 md:1/3 sm:w-1/2 w-full pr-0">
                                    <div className='py-2'>
                                        {item.parsed_tags && Object.entries(item.parsed_tags).slice(0, 3).map(([key, value], tIndex) => {
                                            return(
                                                <div key={'tag_'+tIndex} className={`flex ${!tIndex ? "" : "pt-2"}`}>
                                                    <div className="py-1 w-full">
                                                        <p className="b-block mb-0">{key}</p>
                                                        {value ? 
                                                            <p className="mb-0 text-black">{value}</p>   
                                                        : 
                                                            <span>&nbsp;</span>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="lg:w-1/4 md:1/3 sm:w-1/2 w-full pr-0">
                                    <div className='py-2'>
                                        {item.parsed_tags && Object.entries(item.parsed_tags).slice(3, 6).map(([key, value], tIndex) => {
                                            return(
                                                <div key={"nTag_"+tIndex} className={`flex ${!tIndex ? "" : "pt-2"}`}>
                                                    <div className="py-1 w-full">
                                                        <p className="b-block mb-0">{key}</p>
                                                        {value ? 
                                                            <p className="mb-0 text-black">{value}</p>   
                                                        : 
                                                            <span>&nbsp;</span>
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="lg:w-1/4 md:1/3 sm:w-1/2 w-full pr-0">
                                    <div className='py-2 flex'>
                                        <div className='p-2 rounded-md border border-lightGray mr-2 w-4/5'>
                                            <div className="flex">
                                                <div className="py-1 w-full">
                                                    <p className="b-block mb-0">Status</p>
                                                    <p className="mb-0 text-white">
                                                        {item.status ?
                                                            <span className={`border border-badgeBorder bg-${item.status === 'up' ? 'appleGreen' : 'badgeBg'} px-2 rounded-full text-black text-xs break-all`}>{item.status} </span>
                                                        : null}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="py-1 w-full">
                                                    <p className="b-block mb-0">Days Running</p>
                                                    <p className="mb-0 text-black">
                                                        <span>{item.number_of_days ? item.number_of_days : 0}</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <div className="py-1 w-1/2">
                                                    <p className="b-block mb-0">Created at</p>
                                                    <p className="mb-0 text-black">{item.created_at ? momentConvertionUtcToLocalTime(item.created_at, 'DD MMM YYYY HH:mm') : ''}</p>
                                                </div>
                                                <div className="py-1 w-1/2">
                                                    <p className="b-block mb-0">Modified at</p>
                                                    <p className="mb-0 text-black">{item.last_modified_at ?  momentConvertionUtcToLocalTime(item.last_modified_at, 'DD MMM YYYY HH:mm') : 'Not Modified'}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='ml-3 self-center'>
                                            {/* <p className='mb-0 text-center text-info' onClick={() => setState(prevState => ({ ...prevState, editQueueDetails: item, pageMode: 'View' }))}>View</p> */}
                                            <p className='mb-0 text-center text-info cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedQueueDetails: item }))}>Details</p>
                                            {state.actionsAllowed && state.actionsAllowed.includes('update') ?
                                                <p className='mb-0 text-center mt-2 text-info cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, editQueueDetails: item, pageMode: 'Edit' }))}>Edit</p>
                                            : null}
                                            {state.actionsAllowed && state.actionsAllowed.includes('delete') ?
                                                <p className='mb-0 text-center mt-2 text-danger cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDeleteItem: item, callDelete: true }))}>Delete</p>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                : 
                    <div className='flex justify-center text-black m-4'>
                        There are no data on this criteria. Please try adjusting your filter.
                    </div>
                }
                <div id="popup-modal" tabIndex="-1" className={`flex ${state.deleteModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black" data-modal-hide="popup-modal" onClick={() => setState(prevstate => ({ ...prevstate, deleteModal: false, showDeleteLoading: false, deleteWorksatationDetails: {} }))}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete the queue "{state.selectedDeleteItem && state.selectedDeleteItem.queue_name ? state.selectedDeleteItem.queue_name : ''}"?</h3>

                                <p>Please enter the queue name to delete</p>
                                <input
                                    type='text'
                                    placeholder={'Enter cluster name'}
                                    className={`inputTextbox bg-transparent rounded-sm px-1 border border-mediumDarkGray text-black`}
                                    // maxlength='128'
                                    value={state.queue_name ? state.queue_name : ''}
                                    onChange={e => handleInputChange('queue_name', e.target.value)}
                                />
                                <div className='flex mt-4 justify-center'>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-HalloweenOrange mr-2" 
                                    onClick={() => {
                                        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
                                        if(!state.showDeleteLoading) {
                                            deleteQueueFunction()
                                        }
                                    }}
                                >
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md bg-lightGray text-white" onClick={() => {
                                    if(!state.showDeleteLoading) {
                                        setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false, name: '' }))
                                    }
                                }}>
                                No, cancel
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {state.totalRecords > state.perPage ?
                    <div className='flex justify-end text-black w-full mt-2'>
                        <span className='mx-3'>Page <strong>{state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)}</strong> </span>
                        <div className="pagination text-sm">
                            <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                            <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button> 
                            {/* <span>
                                Go to
                                <input
                                    type='number'
                                    value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentUpcoming}
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) : ""
                                        gotoPage(page)
                                    }}
                                    className="ml-1 inputClass"
                                />
                            </span> */}
                            <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                            <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                        </div>
                    </div>
                : null}
            </div>
        </div>
    )
}
export default QueueDetails