import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Link, useLocation } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'
import { listWorkstationResults, applyWorkstationActions, getCatalogFilters, disableLifecyclePolicy } from '../../../actions/CatalogAction'
import { convertBytes, convertSeconds, convertBits, countFormater, } from '../../../utils/utility'
import windows from '../../../assets/images/windows-pngwing.png'
import linux from '../../../assets/images/linux-pngwing.png'
import aws from '../../../assets/images/aws-black-pngwing.png'
import gcp from '../../../assets/images/gcp-pngwing.png'
// import ubuntu from '../../../assets/images/ubuntu-pngwing.png'
// import * as ServiceController  from "windows-service-controller";

import ApexListBarChart from '../../common/charts/listCharts/ApexListBarChart'
import ApexListLineChart from '../../common/charts/listCharts/ApexListLineChart'

import WorkstationSidePanel from './WorkstationSidePanel'
import Search from '../../common/SearchWithHiddenInput'
import { Store as CommonNotification } from 'react-notifications-component';

// import ConfirmationScreen from '../../common/ConfirmationScreen';
import {Icon} from "@iconify/react";
let searchWorstation = null

const Workstation = (props) => {

    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,
        catalogType: props.selecedProvider && props.selecedProvider.toLowerCase(),
        selectedAccount: props.selectedAccount,
        selectedRegion: props.selectedRegion,
        emptyOptions: [],
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 5,
        actions: [
            {value: 'start', label: 'Start'},
            {value: 'stop', label: 'Stop'},
            {value: 'modify', label: 'Modify'},
            {value: 'terminate', label: 'Terminate'}
        ]
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation();
    const receivedData = location.state;
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    // const locationData = location.state || false
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    let trimmedPath = currentPath
                    if(row.submenulist.filter(e => e.link === trimmedPath && e.workstation_action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === trimmedPath)[0].workstation_action
                    }
				} else if(row.workstation_action) {
                    actionsAllowed = row.workstation_action
                }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    const handleCatalogTypeChange = (event) => {
        setState(prevState => ({ ...prevState, showLoading: true, listCatalogs: [], filteredCatalogs: [], nextCatalogToken: [], catalogType: event.target.value, callListWorkstationResults: true }))
    }

    useEffect(() => {
        let params = {}
        if(state.selectedProvider && state.selectedProvider !== '') {
            params.provider = state.selectedProvider.toLowerCase()
        }
        dispatch(getCatalogFilters(params))
            .then((response) => {
                if(response) {
                    let results = response.results ? response.results : []
                    let mappings = response.mappings ? response.mappings : []                    
                    setState(prevState => ({ ...prevState, catalogTypes: mappings, catalogResponse: results, catalogType: state.selectedProvider.toLowerCase(),  callListWorkstationResults: true }))
                }
            })
    }, [state.selectedProvider, dispatch])

    useEffect(() => {
        if(state.resetFilter) {
            setState(prevState => ({ ...prevState, resetFilter: false }));
        }
    }, [state.resetFilter]);

    useEffect(() => {
        if(state.callListWorkstationResults) {
            setState(prevState => ({ ...prevState, callListWorkstationResults: false }))
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== "All")
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== "All")
            }
            
            if(props.subscribedRow && props.subscribedRow.workstation_id) {
                params.workstation_id = props.subscribedRow && props.subscribedRow.workstation_id
            }

            if(state.catalogType) {
                params.catalog_type = state.catalogType.toLowerCase()
            }

            if(state.searchText) {
                params.search_input = state.searchText
            }

            if(state.nextCatalogToken && state.nextCatalogToken.length) {
                params.next_token = state.nextCatalogToken
            }

            params.size = state.perPage
    
            dispatch(listWorkstationResults(params))
                .then((response) => {
					if(response) {
                        
                        let totalResults = [] 
                        let startRecord = state.startRecord
                        let currentPage = state.currentPage 
                        if(state.nextCatalogToken && state.nextCatalogToken.length) {
                            totalResults = state.workstationList &&  state.workstationList.length ? state.workstationList : []
                            startRecord = startRecord + state.perPage;
                            currentPage = currentPage+ 1
                        }

                        if(response.results && response.results.length) {
                            totalResults = totalResults.concat(response.results)
                            // if(state.nextCatalogToken && state.nextCatalogToken.length) {
                            //     setState(prevState => ({ ...prevState, startRecord: state.startRecord + state.perPage }))
                            // }
                        }

                        let totalPages = 1                
                        if(totalResults && totalResults.length > state.perPage) {
                            totalPages = Math.ceil(totalResults.length / state.perPage)
                        }

                        let json = response.workstation_details ? response.workstation_details : []

                        let detailsFormat = _.orderBy(json, ['order'], ['asc'])

                        setState(prevState => ({ ...prevState, workstationList: totalResults, filterdWorkStationList: totalResults, totalRecords: response.total ? response.total : 0, selectedFilters: params, detailsFormat, showLoading: false, callFormGraph: true, totalPages, startRecord, currentPage, nextCatalogToken: response.next_token }));
                    }
                })
        }
    }, [state.callListWorkstationResults, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, receivedData, state.workstationList, state.nextCatalogToken, props, state.startRecord, state.currentPage, state.perPage, state.catalogType, state.searchText])

    

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextCatalogToken && state.nextCatalogToken.length) {
            setState(prevState => ({ ...prevState, showLoading: true, callListWorkstationResults: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    const getGrapUnitValue = useCallback((val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                if(valueType === "round") {
                    val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(0))
                } else {
                    val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                }
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit, valueType)
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {          
            if(valueType === "round") { 
                val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(0))
            } else {
                val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
            }
        }
        return val
    }, [])

    useEffect(() => {
        if(state.callFormGraph) {
            let graphDetails = state.filterdWorkStationList ? state.filterdWorkStationList : []
            if(graphDetails && graphDetails.length) {
                graphDetails.forEach(row => {
                    if(row.metrics && Object.entries(row.metrics).length) {
                        let graph = row.metrics
                        let graphData = graph.data
                        let graphLabels = graph.dates                

                        let cpuRow = {}
                        let cpuSum = 0

                        let memoryRow = {}
                        let memorySum = 0
                        
                        let diskRow = {}
                        let diskSum = 0

                        let networkRow = []
                        let networkAvg = []

                        Object.entries(graphData).forEach(([key, item]) => {                    
                            let avgData = {}
                            if(item.statistics) {
                                if(item.statistics.avg) {
                                    if(item.unit) {
                                        // avgData.avg = item.statistics.avg 
                                        avgData.avg = getGrapUnitValue(item.statistics.avg, item.unit, "round")
                                    } else {
                                        avgData.avg = item.statistics.avg
                                    }
                                }
                                if(item.statistics.max) {
                                    if(item.unit) {
                                        avgData.max = getGrapUnitValue(item.statistics.max, item.unit, "round")
                                    } else {
                                        avgData.max = item.statistics.max
                                    }
                                }
                            }
                            if(item.category === "CPU") {
                                let data = []                        
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        cpuSum += val
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }    
                                cpuRow.data = data
                                cpuRow.labels = graphLabels
                                
                                setState(prevState => ({ ...prevState, ['statistics_cpu_'+row.workstation_id]: avgData }))
                            } else if(item.category === "Memory") {
                                let data = []
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        memorySum += val
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }
                                
                                memoryRow.data = data
                                memoryRow.labels = graphLabels
                                setState(prevState => ({ ...prevState, ['statistics_memory_'+row.workstation_id]: avgData }))
                            } else if(item.category === "Disk") {
                                let data = []
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        diskSum += val
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }    
                                diskRow.data = data
                                diskRow.labels = graphLabels

                                setState(prevState => ({ ...prevState, ['statistics_disk_'+row.workstation_id]: avgData }))
                            } else if(item.category === "Network") {
                                let data = []
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }
                                
                                let newData = networkRow && networkRow.data ? networkRow.data : {}
                                newData[key] = data
                                
                                networkRow.data = newData
                                networkRow.labels = graphLabels
        
                                if(item.statistics) {
                                    if(item.statistics.avg) {
                                        let avg = 0
                                        if(item.unit) {
                                            avg = getGrapUnitValue(item.statistics.avg, item.unit, "round")
                                        } else {
                                            avg = item.statistics.avg
                                        }
                                        networkAvg.push(avg)
                                    }
                                }
        
                                setState(prevState => ({ ...prevState, ['statistics_network_'+row.workstation_id]: networkAvg }))
                            }
                        })
                        if(cpuSum > 0 && cpuRow && Object.entries(cpuRow).length) {
                            setState(prevState =>  ({ ...prevState, ['cpu_'+row.workstation_id]: cpuRow }))
                        }
                        if(memorySum > 0 && memoryRow && Object.entries(memoryRow).length) {
                            setState(prevState =>  ({ ...prevState, ['memory_'+row.workstation_id]: memoryRow }))
                        } 
                        if(diskSum > 0 && diskRow && Object.entries(diskRow).length) {
                            setState(prevState =>  ({ ...prevState,['disk_'+row.workstation_id]: diskRow }))
                        }
                        if(networkRow && Object.entries(networkRow).length) {
                            setState(prevState =>  ({ ...prevState,['network_'+row.workstation_id]: networkRow }))
                        }
                    }
                })
            }

        }
    }, [state.callFormGraph, state.filterdWorkStationList, getGrapUnitValue])

    const onChangeAction = (row, option) => {
        if(option === 'terminate') {
            setState(prevState => ({ ...prevState, terminateModal: true, selectedTerminateDetails: row }))
        } else if(option === "details") {
            setState(prevState => ({ ...prevState, selectedDetails: row }))
        } else {
            setState(prevState => ({ ...prevState, selectedDetails: row, selectedAction: option }))
        }
    }

    // const onChangeActionNew = () => {
    //     setState(prevState => ({ ...prevState, selectedDetails: row, showConfirmationScreen: true }))
    // }

    // useEffect(() => {
    //     setState(prevState => ({ ...prevState, selectedDetails: row }))
    // }, [state.updateSelectedAction])

    const deleteWorkstationFunction = () => {
        let params = {}
        params.workstation_id = state.deleteWorksatationDetails && state.deleteWorksatationDetails.workstation_id
        params.action = 'unsubscribe'
        if(params.workstation_id) {
            dispatch(applyWorkstationActions(params)).then((response) => {
                let messageType = 'success'
                let message = response.message
                if(!response.status) {   
                    messageType = 'danger'
                    setState(prevState => ({ ...prevState,  showDeleteLoading: false }))
                } else {
                    setState(prevState => ({ ...prevState, deleteModal: false, showDeleteLoading: false, nextCatalogToken: [], callListWorkstationResults: true }))
                }
                // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })   
        }
    }

    const terminateFunction = () => {
        let params = {}
        params.workstation_id = state.selectedTerminateDetails && state.selectedTerminateDetails.workstation_id
        params.action = 'terminate'
        if(params.workstation_id) {
            dispatch(applyWorkstationActions(params)).then((response) => {
                let messageType = 'success'
                let message = response.message
                if(!response.status) {   
                    messageType = 'danger'
                    setState(prevState => ({ ...prevState, showTerminateLoading: false }))
                } else {
                    setState(prevState => ({ ...prevState, terminateModal: false, showTerminateLoading: false, callListWorkstationResults: true }))
                }
                // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })   
        }
    }

    const callDisableFuction = (row, disabled_policy) => {

		let params = {
			disable: disabled_policy ? true : false,			
			workstation_id: row && row.workstation_id
		}
		
		dispatch(disableLifecyclePolicy(params))
			.then((response) => {
				let messageType = ""
				if(response.status) {
					messageType = 'success'
					// setState(prevState => ({ ...prevState, callListLifecyclePolicies: true }))
				} else {
					messageType = 'danger'
				}
				setState(prevState => ({ ...prevState, submitLoading: false }))
	
				let message = response && response.message ? response.message : 'Error in disabling policy '
	
				CommonNotification.addNotification({
					message: message,
					type: messageType,
					insert: "top",
					container: "top-center",
					dismiss: {
						duration: 5000,
						onScreen: false,
						pauseOnHover: true,
						showIcon: true,
					}
				});
			})
	}

    return (
        state.showLoading ?
            <div className="w-full flex justify-center mt-4">
                <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="currentColor" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                    <path d="M4 12a8 8 0 0112-6.9" />
                </svg>
            </div>
        :
            <React.Fragment>
            {state.selectedDetails && Object.entries(state.selectedDetails).length ? 
                <WorkstationSidePanel 
                    closeSidePanel={(string) => setState(prevState => ({...prevState, selectedDetails: {}, selectedAction: "", callListWorkstationResults: string === 'refresh' ? true : false, nextCatalogToken: string === 'refresh' ? [] : state.nextCatalogToken, showLoading: false }))}
                    selectedDetails={state.selectedDetails} 
                    selectedAction={state.selectedAction}
                    detailsFormat={state.detailsFormat}
                />
            : null}
            <div className='p-2'>
                <div className='flex justify-between mb-3'>
                    <p className='mb-0 self-center lg:w-1/2 w-full'>
                        <small>Showing {state.filterdWorkStationList && state.filterdWorkStationList.length} out of total {state.totalRecords} workstation(s)</small>
                        {state.selectedFilters && state.selectedFilters.provider ?
                            <React.Fragment>
                            <small className='ml-1'>obtained from the</small>
                            <small className='ml-1 text-info'>{state.selectedFilters.provider.toUpperCase()}</small>
                            <small className='ml-1'>provider</small>
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.account_id && state.selectedFilters.account_id.length ?
                            <React.Fragment>
                            <small className='ml-1'>with the account</small>
                            <small className='border border-info rounded-full px-2 py-1 mx-1'>{state.selectedFilters.account_id[0]}</small>
                            {state.selectedFilters.account_id.length > 1 ?
                                <small className='ml-2 text-info'>more</small>
                            : null}
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.region && state.selectedFilters.region.length ?
                            <React.Fragment>
                            <small className='ml-1'>in the</small>
                            <small className='border border-info rounded-full px-2 py-1 mx-1'>{state.selectedFilters.region[0]}</small>
                            {state.selectedFilters.region.length > 1 ?
                                <small className='ml-2 text-info'>more</small>
                            : null}
                            <small className='ml-1'>region</small>
                            </React.Fragment>
                        : null}
                    </p>
                    <div className='flex mr-2 justify-end lg:w-1/2 w-full'>
                        <select className="self-center bg-transparent border-b text-black text-xs lg:w-1/6 w-2/5 p-2 focus-visible:border-b border-mediumDarkGray mr-2" onChange={handleCatalogTypeChange} value={state.catalogType}>
                            {state.catalogTypes && state.catalogTypes.map((cat) => {
                                return(
                                    <option key={cat.value} value={cat.value}>{cat.label}</option>
                                )
                            })}
                        </select>
                        <div className='lg:w-1/3 w-3/5 justify-end self-center'>
                            <div className='flex border border-lightGray rounded-md'>
                                <Icon icon={`bitcoin-icons:search-outline`} className={`text-black w-5 self-center`} width={25} height={25} />
                                <div className={`w-11/12`}>
                                    <input
                                        value={state.searchText}
                                        onChange={e => {
                                            setState(prevState => ({ ...prevState, searchText: e.target.value }))
                                            clearTimeout(searchWorstation)
                                            if(e.target.value.length > 2) {
                                                searchWorstation = setTimeout(() => { setState(prevState => ({ ...prevState, searchText: e.target.value, nextCatalogToken: [], startRecord: 0, currentPage: 1, totalPages: 1, workstationList: [], showLoading: true, callListWorkstationResults: true }))}, 1000); 
                                            } else if(!e.target.value.length) {
                                                setState(prevState => ({ ...prevState, startRecord: 0, currentPage: 1, totalPages: 1, workstationList: [], showLoading: true, callListWorkstationResults: true }))
                                            }
                                        }}
                                        type='text'
                                        className={`bg-transparent text-black text-md pl-1 py-1`}
                                        placeholder='Search'
                                    />
                                </div>
                            </div>
                            <div className='hidden'>
                                <Search
                                    data={state.workstationList ? state.workstationList : []}
                                    applyTags={false}
                                    applyLiteDarkTags={true}
                                    topClassName={'bg-darkGray text-black f12 border-mediumDarkGray'}
                                    searchClassName={'px-2 f12'}
                                    searchIconColor={'text-lightGray f12'}
                                    searchPlaceHolder={'Search....'}
                                    className={'bg-transparent pl-2 text-black'}
                                    filteredData={(filterdWorkStationList) => {
                                        let totalPages = 1                
                                        if(filterdWorkStationList.length > state.perPage) {
                                            totalPages = Math.ceil(filterdWorkStationList.length / state.perPage)
                                        }
                                        setState(prevState => ({ ...prevState, filterdWorkStationList, totalPages }))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {state.workstationList && state.workstationList.length ?
                    state.totalRecords > state.perPage ?
                        <div className='flex justify-end text-black w-full'>
                            <span className='mx-3'>Page <strong>{state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)}</strong> </span>
                            <div className="pagination text-sm">
                                <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                                <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button> 
                                <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                                <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                            </div>
                        </div>
                    : null
                : null}
                <div className=''>
                    {state.filterdWorkStationList && state.filterdWorkStationList.length ?
                        state.filterdWorkStationList.slice(state.startRecord, state.startRecord + state.perPage).map((row, index) => {
                            return(
                                <div className="flex flex-wrap mb-2 bg-GhostWhite" key={"row_"+(state.startRecord + index)}>
                                    <div className="w-full lg:w-1/4 md:w-1/2 pr-0 flex self-center bg-BlockWhite min-h-[228px] lg:flex">
                                        <div className="flex px-3 py-2">
                                            <div className="flex justify-between mb-1">
                                                <div style={{minWidth: "30px", maxWidth: "30px"}} className='self-center'>
                                                    {row.platform === "windows" ?
                                                        <img src={windows} className="img-fluid mb-3" alt="WINDOWS" />
                                                    :
                                                        <img src={linux} className="img-fluid mb-3" alt="LINUX"/>
                                                    }
                                                    {row.provider === "aws" || row.provider === "AWS" ?
                                                        <img src={aws} className="img-fluid mb-3" alt="AWS" />
                                                    :
                                                        <img src={gcp} className="img-fluid mb-3" alt="GCP" />
                                                    }
                                                </div>
                                                <div className="ml-3 self-center">
                                                    <p className='mb-2 text-lightGray'>Name <span className='text-black ml-2'>{row.image_name}</span></p>
                                                    <p className='mb-2 text-lightGray'>Os <span className='text-black ml-2'>{row.platform}</span></p>
                                                    <p className='mb-2 text-lightGray'>
                                                        <span className="mr-1">Resources </span>
                                                        <span className='text-black'>
                                                            {row.provider ? row.provider.toUpperCase() : <span>&nbsp;</span>}
                                                            {row.account_id ? (' : ')+row.account_id : <span>&nbsp;</span>}
                                                            {row.region ?  (' : ')+row.region : <span>&nbsp;</span>}
                                                        </span>
                                                    </p>
                                                    <p className='mb-2 truncateChar text-lightGray'>Description <span className='text-black ml-2'>{row.description}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-1/4 md:w-1/2 md:mb-0 sm:mb-0 lg:mb-0 mb-3 self-center pl-3">
                                        <p className="mb-1 flex">
                                            <span className='minWidth60 text-lightGray'>Name</span>
                                            <span className='text-black ml-2'>{row.workstation_name} </span>
                                        </p>
                                        <p className='mb-1 flex'> 
                                            <span className='minWidth60 text-lightGray'>Status</span> 
                                            <span className={`rounded-full cursor-pointer px-2 border border-${row.status === "failed" ? "red" : row.status === "stopped" ? "YellowOrange" : row.status === "hibernated" ? "YellowOrange" : "info"} text-${row.status === "failed" ? "red" : row.status === "stopped" ? "YellowOrange" : row.status === "hibernated" ? "YellowOrange" : "info"}  ml-2`} onClick={() => setState(prevState => ({ ...prevState, selectedDetails: row }))}>{row.status}</span>
                                            {row.status_datetime ? 
                                                <span className='ml-2 text-black'>({row.status_datetime})</span> 
                                            : null}
                                        </p>
                                        
                                        <p className='mb-1 flex'>
                                            <span className='minWidth60 text-lightGray'>Capacity</span>
                                            <span className='text-black ml-2'>{row.instance_type} </span>
                                            <span className='text-black ml-2'>(
                                                {row.gpu ? <span>{row.gpu+" GPU, "}</span> : null}
                                                {row.vcpus} vCPU,
                                                <span className='ml-1'>{row.memory} {row.memory_unit ? row.memory_unit : 'GB'} </span>
                                            )</span>
                                        </p>
                                        {row.id_name_map ?
                                            <p className='mb-1 flex cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: row }))}>
                                                <span className='minWidth60 text-lightGray'>Asset</span>
                                                <span className='ml-2 text-black'>{(row.id_name_map.value ? row.id_name_map.value : "")}
                                                {row.id_name_map && row.id_name_map.key ?
                                                    (<span className={`ml-1 text-info mb-0`}>
                                                    {row.id_name_map.key }</span>)
                                                : null}
                                                </span>
                                            </p>
                                        : row.asset_name ?
                                            <p className='mb-1 flex cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: row }))}> 
                                                <span className='minWidth60 text-lightGray'>Asset</span>  
                                                <span className='text-black ml-2'>{row.asset_name} </span>
                                            </p>
                                        : null}
                                        {row.public_ip_address ?
                                            <p className='mb-1 flex'>
                                                <span className='minWidth60 text-lightGray'>Public IP</span>
                                                <span className='text-black ml-2'>{row.public_ip_address} </span>
                                            </p>
                                        : row.private_ip_address ?
                                            <p className='mb-1 flex'>
                                                <span className='minWidth60 text-lightGray'>Private IP</span>
                                                <span className='text-black ml-2'>{row.private_ip_address} </span>
                                            </p>
                                        : null}
                                        {row.volume_details && row.volume_details.length ?
                                            <p className='mb-1 flex'>
                                                <span className='minWidth60 text-lightGray'>Volume</span>
                                                <span className='text-black ml-2'>
                                                    {row.volume_details[0].volume_id ? row.volume_details[0].volume_id : ''} 
                                                </span>
                                                {row.volume_details[0].volume_size ?
                                                    <span className='text-black ml-2'>({row.volume_details[0].volume_size} GB)</span>
                                                : null}
                                            </p>
                                        : null}
                                        {row.user_name ?
                                            Array.isArray(row.user_name) && row.user_name.length ?
                                                <p className='mb-1 flex'>
                                                    <span className='minWidth60 text-lightGray'>User</span>
                                                    <span className='text-black ml-2'>{row.user_name[0]} </span>
                                                    {row.user_name.length > 1 ?
                                                        <small className='self-center ml-2 text-info cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: row }))}>more</small>
                                                    : null}
                                                </p>
                                            :
                                                <p className='mb-1 flex'>
                                                    <span className='minWidth60 text-lightGray'>User</span>
                                                    <span className='text-black ml-2'>{row.user_name} </span>
                                                </p>
                                        : null}
                                        {row.nicedcv_link ?
                                        <div className='self-center text-info flex justify-center'>
                                            <Link to={row.nicedcv_link} className='text-info text-decoration-none' target="_blank" rel="noopener noreferrer">
                                                Connect via NiceDCV
                                            </Link>
                                        </div>
                                    : null}
                                    </div>
                                    <div className="w-full lg:w-1/4 lg:px-0 px-2 lg:mt-0 mt-3 self-center">
                                        <div className="flex flex-wrap border-b border-darkGray">
                                            <div className="w-full lg:w-1/2 md:w-1/2 pr-0 pt-1">
                                                <p className='mb-1'>CPU</p>
                                                {state["cpu_"+row.workstation_id] ? 
                                                    <div className="flex justify-between mb-3">
                                                        <div className="w-3/4">
                                                            <ApexListBarChart
                                                                graphData={state["cpu_"+row.workstation_id]}
                                                                name={'Line'}
                                                                xaxis={false}
                                                                xaxisFormat={'datetime'}
                                                                yaxis={false}
                                                                yaxisLabel={false}
                                                                markerSize={0}
                                                                hideMarkerLength={30}
                                                                gradient={false}
                                                                gradientColor={['#039BE5', '#039BE5']}
                                                                colors={['#039BE5']}
                                                                sparkline={true}
                                                                zoom={false}
                                                                height={34}
                                                                width={'100%'}
                                                                grid={false}
                                                                className={"transparentTooltip mb-n2"}
                                                                tooltip={true}
                                                                stacked={false}
                                                                animations={false}
                                                            />
                                                        </div>
                                                        {state["statistics_cpu_"+row.workstation_id] && Object.entries(state["statistics_cpu_"+row.workstation_id]).length ?
                                                            <div className="w-1/4 pl-1 pt-1 mb-0 self-center f11">
                                                                <p className="flex mb-2 text-lightGray text-xs">Avg <span className="pl-1 text-lightGray">{state["statistics_cpu_"+row.workstation_id].avg && state["statistics_cpu_"+row.workstation_id].avg ? state["statistics_cpu_"+row.workstation_id].avg : 0}</span></p>
                                                                <p className="flex mb-0 text-lightGray text-xs">Max <span className="pl-1 text-lightGray">{state["statistics_cpu_"+row.workstation_id].max && state["statistics_cpu_"+row.workstation_id].max}</span></p>
                                                            </div>
                                                        : null}
                                                    </div>
                                                :
                                                    <p className='mb-0 mt-2 text-black'>NA</p>
                                                }
                                            </div>
                                            <div className="w-full lg:w-1/2 md:w-1/2 pr-0 pt-1 lg:pl-3 md:pl-3 pl-0">
                                                <p className='mb-1'>Memory</p>
                                                {state["memory_"+row.workstation_id] ? 
                                                    <div className="flex justify-between">
                                                        <div className="w-3/5">
                                                            <ApexListBarChart
                                                                graphData={state["memory_"+row.workstation_id]}
                                                                name={'Line'}
                                                                xaxis={false}
                                                                xaxisFormat={'datetime'}
                                                                yaxis={false}
                                                                yaxisLabel={false}
                                                                markerSize={0}
                                                                hideMarkerLength={30}
                                                                gradient={false}
                                                                gradientColor={['#039BE5', '#039BE5']}
                                                                colors={['#039BE5']}
                                                                sparkline={true}
                                                                zoom={false}
                                                                height={34}
                                                                width={'100%'}
                                                                grid={false}
                                                                className={"transparentTooltip mb-n2"}
                                                                tooltip={true}
                                                                stacked={false}
                                                                animations={false}
                                                            />
                                                        </div>
                                                        {state["statistics_memory_"+row.workstation_id] && Object.entries(state["statistics_memory_"+row.workstation_id]).length ?
                                                            <div className="w-1/4 pl-1 pt-1 mb-0 self-center f11">
                                                                <p className="flex mb-2 text-lightGray text-xs">Avg <span className="pl-1 text-lightGray">{state["statistics_memory_"+row.workstation_id].avg && state["statistics_memory_"+row.workstation_id].avg ? state["statistics_memory_"+row.workstation_id].avg : 0}</span></p>
                                                                <p className="flex mb-0 text-lightGray text-xs">Max <span className="pl-1 text-lightGray">{state["statistics_memory_"+row.workstation_id].max && state["statistics_memory_"+row.workstation_id].max}</span></p>
                                                            </div>
                                                        : null}                                                      
                                                    </div>
                                                :
                                                    <p className='mb-0 mt-2 text-black'>NA</p>
                                                }
                                            </div>
                                        </div>
                                        <div className='flex flex-wrap'>
                                            <div className="w-full lg:w-1/2 md:w-1/2 pr-0 pt-1">
                                                <p className='mb-1 pt-1'>Network</p>
                                                {state["network_"+row.workstation_id] ? 
                                                    <div className="flex justify-between">
                                                        <div className="w-3/4">
                                                            <ApexListLineChart 
                                                                graphData={state["network_"+row.workstation_id]}
                                                                name={'Line'}
                                                                xaxis={false}
                                                                xaxisFormat={'datetime'}
                                                                yaxis={false}
                                                                yaxisLabel={false}
                                                                markerSize={0}
                                                                hideMarkerLength={30}
                                                                gradient={false}
                                                                gradientColor={['#039BE5', '#039BE5']}
                                                                colors={['#775BA2', '#039BE5']}
                                                                sparkline={true}
                                                                zoom={false}
                                                                height={34}
                                                                width={'100%'}
                                                                grid={false}
                                                                className={"transparentTooltip metricsDetailCurrentTooltipAlignment"}
                                                                tooltip={true}
                                                                stacked={true}
                                                                axisBorder={true}
                                                            />
                                                        </div>
                                                        {state["statistics_network_in_"+row.workstation_id] && Object.entries(state["statistics_network_in_"+row.workstation_id]).length ?
                                                            <div className="w-1/4 pl-1 pt-1 mb-0 self-center f11">
                                                                <p className="flex mb-2 text-lightGray text-xs">Avg <span className="pl-1 text-lightGray">{state["statistics_network_in_"+row.workstation_id].avg && state["statistics_network_in_"+row.workstation_id].avg ? state["statistics_network_in_"+row.workstation_id].avg : 0}</span></p>
                                                                <p className="flex mb-0 text-lightGray text-xs">Max <span className="pl-1 text-lightGray">{state["statistics_network_in_"+row.workstation_id].max && state["statistics_network_in_"+row.workstation_id].max}</span></p>
                                                            </div>
                                                        : null}  
                                                    </div>
                                                :
                                                    <p className='mb-0 mt-2 text-black'>NA</p>
                                                }
                                            </div>
                                            <div className="w-full lg:w-1/2 md:w-1/2 pr-0 pt-1 lg:pl-3 md:pl-3 pl-0">
                                                <p className='mb-1'>Disk</p>
                                                {state["disk_"+row.workstation_id] ? 
                                                    <div className="flex justify-between">
                                                        <div className="w-3/4">
                                                            <ApexListLineChart 
                                                                graphData={state["disk_"+row.workstation_id]}
                                                                name={'Line'}
                                                                xaxis={false}
                                                                xaxisFormat={'datetime'}
                                                                yaxis={false}
                                                                yaxisLabel={false}
                                                                markerSize={0}
                                                                hideMarkerLength={30}
                                                                gradient={false}
                                                                gradientColor={['#039BE5', '#039BE5']}
                                                                colors={['#775BA2', '#039BE5']}
                                                                sparkline={true}
                                                                zoom={false}
                                                                height={34}
                                                                width={'100%'}
                                                                grid={false}
                                                                className={"transparentTooltip metricsDetailCurrentTooltipAlignment"}
                                                                tooltip={true}
                                                                stacked={true}
                                                                axisBorder={true}
                                                            />
                                                        </div>
                                                        {state["statistics_disk_"+row.workstation_id] && Object.entries(state["statistics_disk_"+row.workstation_id]).length ?
                                                            <div className="w-1/4 pl-1 pt-1 mb-0 self-center f11">
                                                                <p className="flex mb-2 text-lightGray text-xs">Avg <span className="pl-1 text-lightGray">{state["statistics_disk_"+row.workstation_id].avg && state["statistics_disk_"+row.workstation_id].avg ? state["statistics_disk_"+row.workstation_id].avg : 0}</span></p>
                                                                <p className="flex mb-0 text-lightGray text-xs">Max <span className="pl-1 text-lightGray">{state["statistics_disk_"+row.workstation_id].max && state["statistics_disk_"+row.workstation_id].max}</span></p>
                                                            </div>
                                                        : null}  
                                                    </div>
                                                :
                                                    <p className='mb-0 mt-2 text-black'>NA</p>
                                                }
                                            </div>
                                        </div>
                                        {/* {row.nicedcv_link ?
                                            <div className="hidden lg:block lg:pt-3 flex justify-center text-info">
                                                <Link to={row.nicedcv_link} className='text-info text-decoration-none' target="_blank" rel="noopener noreferrer">
                                                    Connect via NiceDCV
                                                </Link>
                                            </div>
                                        : null} */}
                                    </div>
                                    <div className="flex flex-wrap w-full lg:w-1/4 lg:px-0 px-2 lg:mt-0 mt-3 self-center">
                                        <div className="w-full lg:w-1/2 md:w-3/5 lg:mt-0 mt-3 self-center">
                                            <div className="mb-4 flex justify-center">
                                                <p className={`mb-0 f18 self-center rounded-full px-2 py-1 border border-${row.status === "failed" ? "red" : row.status === "stopped" ? "YellowOrange" : row.status === "hibernated" ? "YellowOrange" : "SlateBlue"} text-${row.status === "failed" ? "red" : row.status === "stopped" ? "YellowOrange" : row.status === "hibernated" ? "YellowOrange" : "SlateBlue"}`}>{row.status}</p>
                                            </div>
                                            <p className='text-SlateBlue self-center mb-4 text-lg flex justify-center cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: row }))}>View Details</p>
                                            {state.catalogType !== 'citrix' && row.status !== 'requested' && row.status !== 'rejected' ?
                                                <div className=' flex justify-center'>
                                                    {row.asset_id ?
                                                    <select className="bg-transparent border text-black text-sm w-fit py-1 focus-visible:border-b border-mediumDarkGray" onChange={(e) => onChangeAction(row, e.target.value)}>
                                                        <option key="select" className="text-black" value=''>Select</option>
                                                        {/* {state.actions && state.actions.map(item => {
                                                            return(
                                                                <option key={'opt_'+item.value} className="text-black" value={item.value} defaultValue={state.selectedAction === item.value ? true :  false}>{item.label}</option>
                                                            )
                                                        })}
                                                        {row.hibernate ?
                                                            <option key={"opt_hibernate"} className="text-black" value={"hibernate"} defaultValue={state.selectedAction === "hibernate" ? true :  false}>Hibernate</option>
                                                        : null} */}

                                                        {state.actionsAllowed && state.actionsAllowed.includes('start') ?
                                                            <option className="text-black" key={"start"} defaultValue={state.selectedAction === 'start' ? true :  false} value={"start"}>Start</option>
                                                        : null}
                                                        {state.actionsAllowed && state.actionsAllowed.includes('stop') ?
                                                            <option className="text-black" key={"stop"} defaultValue={state.selectedAction === 'stop' ? true :  false} value={"stop"}>Stop</option>
                                                        : null}
                                                        {state.actionsAllowed && state.actionsAllowed.includes('modify') ?
                                                            <option className="text-black" key={"modify"} defaultValue={state.selectedAction === 'modify' ? true :  false} value={"modify"}>Modify</option>
                                                        : null}
                                                        {state.actionsAllowed && state.actionsAllowed.includes('terminate') ?
                                                            <option className="text-white" key={"terminate"} defaultValue={state.selectedAction === 'terminate' ? true :  false} value={"terminate"}>Terminate</option>
                                                        : null}
                                                        {state.actionsAllowed && state.actionsAllowed.includes('hibernate') && row.hibernate ?
                                                            <option className="text-white" key={"hibernate"} defaultValue={state.selectedAction === 'hibernate' ? true :  false} value={"hibernate"}>Hibernate</option>
                                                        : null}
                                                    </select>
                                                    : row.status === "initiated" ?
                                                        <span className={`f13 border border-HalloweenOrange px-2 py-1 text-white`}>
                                                            Launch Initiated    
                                                            {/* <Spinner className='text-center ml-2' color='light' size='sm' />{' '} */}
                                                        </span>
                                                    : state.showButtonLoading && row.workstation_id === state.selectedDetails && state.selectedDetails.workstation_id ?
                                                    
                                                        <button className={`bg-HalloweenOrange hover:bg-HalloweenOrange-700 text-white ml-2 font-bold px-2 py-1 rounded inline-flex items-center self-center`}>
                                                            Launching
                                                            <svg className="animate-spin h-5 w-5 ml-3 text-white" fill="currentColor" viewBox="0 0 24 24">
                                                                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                                <path d="M4 12a8 8 0 0112-6.9" />
                                                            </svg>
                                                        </button>
                                                    : !state.showButtonLoading ?
                                                        <div className='flex'>
                                                            <button className={`bg-HalloweenOrange hover:bg-HalloweenOrange-700 text-white ml-2 font-bold px-2 py-1 rounded inline-flex items-center self-center`}
                                                                onClick={() => onChangeAction(row, "launch")}
                                                            >Launch</button>
                                                            <button className={`bg-red hover:bg-red-700 text-white ml-2 font-bold px-2 py-1 rounded inline-flex items-center self-center`}
                                                                onClick={() => {
                                                                    setState(prevState => ({ ...prevState, deleteModal: true, deleteWorksatationDetails: row }))
                                                                }}
                                                            >Delete</button>
                                                        </div>
                                                    : null}

                                                </div>
                                            : null}

                                            {row.status === 'rejected' || row.status === 'requested' ?
                                                <div className=' flex justify-center'>
                                                    {!state.showButtonLoading ?
                                                        <div className='flex'>
                                                            <button className={`bg-red hover:bg-red-700 text-white ml-2 font-bold px-2 py-1 rounded inline-flex items-center self-center`}
                                                                onClick={() => {
                                                                    setState(prevState => ({ ...prevState, deleteModal: true, deleteWorksatationDetails: row }))
                                                                }}
                                                            >Delete</button>
                                                        </div>
                                                    : null}
                                                    
                                                </div>
                                            : null}      

                                            <div className='flex mt-2 justify-center'>
                                                <div className={`form-check form-check-inline mt-1 mr-2 flex self-center`}>
                                                    <input
                                                        className='form-check-input m-0'
                                                        type='checkbox'
                                                        checked={state['disabled_policy_'+row.workstation_id] === undefined ? row.disable : state['disabled_policy_'+row.workstation_id]}
                                                        onChange={e => {
                                                            let disabled_policy = state['disabled_policy_'+row.workstation_id] === undefined && row.disable ? false : !state['disabled_policy_'+row.workstation_id] 
                                                            setState(prevState => ({ ...prevState, ['disabled_policy_'+row.workstation_id]: disabled_policy }))
                                                            callDisableFuction(row, disabled_policy)
                                                        }}	
                                                        // title={state['disabled_policy_'+row.workstation_id] ? "not enable" : "enable"}								
                                                    />
                                                    {!state['disabled_policy_'+row.workstation_id] ?
                                                        <span className="checkmark" onClick={e => {
                                                            let disabled_policy = !state['disabled_policy_'+row.workstation_id]
                                                            setState(prevState => ({ ...prevState, disabled_policy }))
                                                            callDisableFuction(row, disabled_policy)
                                                        }}></span>
                                                    : null}
                                                </div>
                                                <div className='flex'>
                                                    <span className="mb-0 text-lg text-black text-nowrap">Disable Lifecycle</span>
                                                    {row.lifecycle_message && row.lifecycle_message.length ?
                                                        <span className='self-center ml-1' id={'disable_info_'+index}>
                                                            <Icon icon="fa:info-circle" className="text-black self-center cursor-pointer" />
                                                            <UncontrolledTooltip target={'disable_info_'+index}>
                                                                <div className='bg-black p-2 rounded-md'>
                                                                    <div key={index} className={`flex flex-column py-2 justify-start`}>
                                                                        <ul className="pl-2 mb-0 f12 text-lightGray font-semibold">
                                                                            {row.lifecycle_message ?
                                                                                row.lifecycle_message.map(inf => {
                                                                                    return(
                                                                                        <li className='mb-2'> {inf}</li>
                                                                                    )
                                                                                })
                                                                            : null}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </UncontrolledTooltip>
                                                        </span>
                                                    : null}
                                                </div>
                                            </div>                                   
                                        </div>
                                        <div className="w-full lg:w-1/2 md:w-2/5 lg:mt-0 mt-3 pr-0 self-center flex lg:justify-end justify-center">
                                            <div className="px-3 rounded bg-BlockWhite h-full border border-GreenBlue mr-2 mt-2" style={{minHeight: "210px", maxHeight: "210px"}}>
                                                <div className={`w-full`}>
                                                    <div className="py-1">
                                                        <p className="b-block text-center mb-0 text-lg">Cost ({row.price_unit})</p>
                                                        <p className="ml-2 text-center mb-0 text-YellowOrange text-xl font-bold">{row.billing_total_cost && row.billing_total_cost ? row.billing_total_cost : 0}</p>
                                                    </div>
                                                    <div className='px-3'>
                                                        <div className=''>
                                                            <p className="text-center b-block mb-0 text-sm">OnDemand</p>
                                                            <p className="text-center mb-0 text-YellowOrange text-lg">{row.price_per_hour && row.price_per_hour}</p>
                                                        </div>
                                                        <div className=''>
                                                            <p className="text-center b-block mb-0 text-sm">Current Month</p>
                                                            <p className="text-center mb-0 text-YellowGreen text-lg">{row.billing_monthly_cost ? row.billing_monthly_cost : 0}</p>
                                                        </div>
                                                        <div className=''>
                                                            <p className="text-center b-block mb-0 text-sm">Current Month Hrs</p>
                                                            <p className="text-center mb-0 text-YellowGreen text-lg">{row.current_month_total_hours ? row.current_month_total_hours: 0}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    :
                        <div className='flex justify-center m-4'>
                            There are no data on this criteria. Please try adjusting your filter.
                        </div>
                    }
                </div>
                <div id="popup-modal" tabIndex="-1" className={`flex ${state.deleteModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black" data-modal-hide="popup-modal" onClick={() => setState(prevstate => ({ ...prevstate, deleteModal: false, showDeleteLoading: false, deleteWorksatationDetails: {} }))}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete the workstation "{state.deleteWorksatationDetails && state.deleteWorksatationDetails.workstation_name ? state.deleteWorksatationDetails.workstation_name : ''}"?</h3>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-HalloweenOrange mr-2" 
                                    onClick={() => {
                                        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
                                        if(!state.showDeleteLoading) {
                                            deleteWorkstationFunction()
                                        }
                                    }}
                                >
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-lightGray"onClick={() => {
                                    if(!state.showDeleteLoading) {
                                        setState(prevstate => ({ ...prevstate, deleteModal: false, showDeleteLoading: false }))
                                    }
                                }}>
                                No, cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="popup-modal" tabIndex="-1" className={`flex ${state.terminateModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-black" data-modal-hide="popup-modal" onClick={() => setState(prevstate => ({ ...prevstate, terminateModal: false, showTerminateLoading: false, selectedTerminateDetails: {} }))}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to terminate the workstation "{state.selectedTerminateDetails && state.selectedTerminateDetails.workstation_name ? state.selectedTerminateDetails.workstation_name : ''}"?</h3>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-HalloweenOrange mr-2" 
                                    onClick={() => {
                                        setState(prevState => ({ ...prevState, showTerminateLoading: true }))
                                        if(!state.showTerminateLoading) {
                                            terminateFunction()
                                        }
                                    }}
                                >
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-lightGray"onClick={() => {
                                    if(!state.showTerminateLoading) {
                                        setState(prevState => ({ ...prevState, terminateModal: false, selectedTerminateDetails: {}, showTerminateLoading: false }))
                                    }
                                }}>
                                No, cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
    );
};
export default Workstation