import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {Icon} from "@iconify/react";

import moment from 'moment';
import _ from 'lodash'

import MultiSelectSection from '../../common/MultiSelectSection';
import { currentUTCtime, momentDateGivenFormat } from '../../../utils/utility'
import { COST_DURATION_OPTIONS } from '../../../utils/constants'
import { listUserDetails, listClusterFilters } from '../../../actions/commonActionNew';
import { listQueues, listClusterDetails, listQueueDetails } from '../../../actions/Collider/ClusterAction';
import ApexBarChart from '../../common/charts/ApexBarChart'
import ApexMixedBarLineChart from '../../common/charts/ApexMixedBarLineChart';
import ApexStackedBarChart from '../../common/charts/ApexStackedBarChart';
import { CSVLink } from 'react-csv';


const CostReportSection = (props) => {
    const clickOut = useRef();
    const durationRef = useRef();
    const tableRef = useRef();

    const [state, setState] = useState({
        showLoading: true,
        typeOptions: ['User', 'Location', 'Software'],
        selectedDuration: 'lastMonth',
        periodOptions: [
            {label: "Daily", value: "daily"},
            {label: "Weekly", value: "weekly"},
			{label: "Monthly", value: "monthly"},
			// {label: "Quarterly", value: "quarterly"},
			// {label: "Half Yearly", value: "half-yearly"}
		],
    })

    const [dateState, setDateState] = useState([{
          startDate: new Date(),
          endDate: null,
          key: 'selection'
    }]);

    const dispatch = useDispatch(); // Create a dispatch function
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel()
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown])

    useEffect(() => {
        const currentMonth = new Date().getMonth(); // Month is zero-based (0 for January, 1 for February, ..., 11 for December)

       	let datePickerStartDate = ""
        let datePickerEndDate = ""
        let today = new Date();
        // let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
        if(state.selectedDuration === "currentMonth") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "currentYear") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "lastMonth") {
            datePickerStartDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-01 00:00:00')            
            datePickerEndDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "quarterly") {
            if (currentMonth >= 0 && currentMonth <= 2) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-03-31 23:59:59')
            } else if (currentMonth > 2 && currentMonth < 6) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-04-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else if (currentMonth > 5 && currentMonth < 9) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-09-30 23:59:59')
            } else {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-10-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "half-yearly") {
            if (currentMonth >= 0 && currentMonth <= 5) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "yearly") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
        }
        if(state.selectedDuration !== 'custom') {
            setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));

            let dateSelection= {
				startDate: new Date(datePickerStartDate),
				endDate: new Date(datePickerEndDate),
				key: 'selection'
			}

			setDateState([dateSelection])
        }

	}, [state.selectedDuration, props])

    useEffect(() => {
        if(state.callClusterList) {
            setState(prevState => ({ ...prevState, callClusterList: false }));
            let params = {};
            params.provider = props.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, clusters: response, callQueueList: true }));
                    }
            })
        }
    }, [dispatch, props.selectedProvider, state.selectedAccount, state.selectedRegion, state.callClusterList])

    useEffect(() => {
        if (state.callQueueList) {
            setState(prevState => ({ ...prevState, callQueueList: false }));
            let params = {};
            params.provider = props.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters) {
                params.cluster_name = state.selectedClusters
            }
            dispatch(listQueues(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, queues: response }));
                    }
            })
        }
    }, [dispatch, state.callQueueList, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters])

    useEffect(() => {
        let selectedAccount = props.selectedAccount
        let selectedRegion = props.selectedRegion
        let selectedClusters = props.selectedClusters
        let datePickerStartDate = props.datePickerStartDate
        let datePickerEndDate = props.datePickerEndDate
        let selectedUser = ''

        if(props.selectedReportInput) {
            if(props.selectedReportInput && props.selectedReportInput.account_id && props.selectedReportInput.account_id.length) {
                selectedAccount = props.selectedReportInput.account_id
            }

            if(props.selectedReportInput && props.selectedReportInput.region && props.selectedReportInput.region.length) {
                selectedRegion = props.selectedReportInput.region
            }
            
            if(props.selectedReportInput && props.selectedReportInput.cluster_name && props.selectedReportInput.cluster_name.length) {
                selectedClusters = props.selectedReportInput.cluster_name
            }
            
            if(props.selectedReportInput && props.selectedReportInput.user_name && props.selectedReportInput.user_name.length) {
                selectedUser = props.selectedReportInput.user_name
            }
        }
        setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate, selectedAccount, selectedRegion, selectedClusters, selectedUser, showLoading: true, callClusterList: true, callHpcReports: true }))
        
    }, [props])

    
    useEffect(() => {
        if(state.callHpcReports) {
            setState(prevState => ({ ...prevState, callHpcReports: false, budgetSpendData: {}, budgetSpendBarLineGraphData: {}, spendStackedTrendData: {}, trendTable: [] }))
            
            let params = {}
            params.provider = props.selectedProvider.toLowerCase()
            params.spendboard = true
            params.start_time = state.datePickerStartDate
            params.end_time = state.datePickerEndDate
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters) {
                params.cluster_name = state.selectedClusters
            }
            if(state.selectedQueue) {
                params.queue_name = state.selectedQueue
            }

            if(props.selectedReport.category && props.selectedReport.category.includes('Cluster')) {
                dispatch(listClusterDetails(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results && response.results.length ? response.results : []
                            
                            setState(prevState => ({ ...prevState, responseDetails: results, showLoading: false }));
                        }
                    })
            } else if(state.selectedQueue && props.selectedReport.category && props.selectedReport.category.includes('Queue')) {
                dispatch(listQueueDetails(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results && response.results.length ? response.results : []
                            
                            setState(prevState => ({ ...prevState, responseDetails: results, showLoading: false }));
                        }
                    })
            }
        }
    }, [dispatch, state.callHpcReports])

    useEffect(() => {
        state.responseDetails && state.responseDetails.forEach(item => {
            if(item.trend) {
                let budgetSpendTrendData = item.trend ? item.trend : []
                //horizontal spend and budget bar
                let data = [budgetSpendTrendData && budgetSpendTrendData.budget_total ? budgetSpendTrendData.budget_total : 0, budgetSpendTrendData && budgetSpendTrendData.spend_total ? budgetSpendTrendData.spend_total : 0]
                let labels = ['Budget', 'Spend']
                let dataUnit = ''
            
                let horiBarData = {}
                horiBarData.data = data
                horiBarData.labels = labels        
                horiBarData.unit = dataUnit                
                
                let graphData = {}
                graphData.labels = item.dates ? item.dates : []
                graphData['Budget'] = budgetSpendTrendData.budget
                graphData['Spend'] = budgetSpendTrendData.spend
                
                setState(prevState => ({ ...prevState, budgetSpendData: horiBarData, budgetSpendBarLineGraphData: graphData, budgetSpendTrendData }))
            }
        })

    }, [state.responseDetails])

    useEffect(() => {
        state.responseDetails && state.responseDetails.forEach((item, index) => {
            if(item.breakdown) {
                let spendStackedTrend = item.breakdown
                let graphData = {}
                graphData.labels = item.dates ? item.dates : []
        
                Object.entries(spendStackedTrend).length && spendStackedTrend.spend && Object.entries(spendStackedTrend.spend).forEach(([key, value]) => {
                    graphData[key] = value
                })
                
                setState(prevState => ({ ...prevState, spendStackedTrendData: graphData }))
            }
        })
    }, [state.responseDetails])

    useEffect(() => {
        if(state.responseDetails) {
            var trendTable = []
            state.responseDetails.forEach((item, index) => {

                let budgetSpendTrendData = item.data ? item.data : []

                let jobsData = {}
                jobsData.labels = item.dates ? item.dates : []
                jobsData.total = budgetSpendTrendData.hasOwnProperty('jobs') ? budgetSpendTrendData['jobs'] : []
                jobsData.success = budgetSpendTrendData.hasOwnProperty('success') ? budgetSpendTrendData['success'] : []
                jobsData.failed = budgetSpendTrendData.hasOwnProperty('failed') ? budgetSpendTrendData['failed'] : []
                
                let nodesData = {}
                nodesData.labels = item.dates ? item.dates : []
                nodesData.nodes = budgetSpendTrendData.hasOwnProperty('nodes') ? budgetSpendTrendData['nodes'] : []

                let vcpusData = {}
                vcpusData.labels = item.dates ? item.dates : []
                vcpusData.vxpus = budgetSpendTrendData.hasOwnProperty('cpus') ? budgetSpendTrendData['cpus'] : []

                let cpuHours = {}
                cpuHours.labels = item.dates ? item.dates : []
                cpuHours.cpu_hours = budgetSpendTrendData.hasOwnProperty('cpu_hours') ? budgetSpendTrendData['cpu_hours'] : []

                let nodeHours = {}
                nodeHours.labels = item.dates ? item.dates : []
                nodeHours.node_hours = budgetSpendTrendData.hasOwnProperty('node_hours') ? budgetSpendTrendData['node_hours'] : []

                setState(prevState => ({ 
                    ...prevState, 
                    ["nodes_"+state.selectedItemIndex]: nodesData,
                    ["cpus_"+state.selectedItemIndex]: vcpusData,
                    ["cpu_hours_"+state.selectedItemIndex]: cpuHours,
                    ["node_hours_"+state.selectedItemIndex]: nodeHours,
                    ["jobs_"+state.selectedItemIndex]: jobsData,                        
                }))

                let labels = item.dates ? item.dates : []
                if(item.breakdown && item.breakdown.spend && Object.entries(item.breakdown.spend).length) {
                    Object.entries(item.breakdown.spend).forEach(([key, value]) => {
                        var result = {
                            "Title": key,
                            'total': item.breakdown.spend_total && item.breakdown.spend_total[key] ? item.breakdown.spend_total[key] : 0
                        }
                        
                        value.forEach((vItem, vIndex) => {
                            result[labels[vIndex]] = vItem
                        })
                        trendTable.push(result);
                    })
                } else if(item.data && Array.isArray(item.data)) {

                    let data = item.data
                    // let totalCount = item.data.reduce(function(a, b){
                    // 	return a + b;
                    // }, 0);
                    var result = {
                        "Title": "Cost",
                        'total': item.total
                    }
                    
                    data.forEach((item,index) => {
                        if(momentDateGivenFormat(labels[index], "YYYY-MM-DD") <= momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
                            result[labels[index]] = item
                        }
                    })
                    
                    if(item.forecast && item.forecast.dates) {
                        item.forecast && item.forecast.dates.forEach((lab, index) => {					
                            result[lab] = item.forecast.data[index]
                        })
                    }

                    trendTable.push(result);
                }
            })
            
            if(trendTable.length) {
                let clusterHeaders = ['Title', 'total']
                const columns = Object.entries(trendTable[0]).map(([tKey, tValue]) =>{
                    let sticky = false
                    let header = ''
                    let width = 100
                    let textColor = ''
                    if(tKey === 'Title') {
                        sticky = true
                        header = tKey
                        width = 300
                    } else if(tKey === 'total') {
                        sticky = true
                        header = 'Total'
                    } else {
                        if(momentDateGivenFormat(tKey, "YYYY-MM-DD") > momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
                            textColor = "text-info"
                        }
                        // if(state.granualrity === 'monthly') {
                        //     header = moment(tKey).format('MMM YY')
                        //     width = 75
                        // } else  if(state.granualrity === 'daily') {
                        //     header = moment(tKey).format('MMM DD')
                        //     width = 75
                        // } else  {
                        header = moment(tKey).format('MMM DD')
                        clusterHeaders.push(tKey)
                        // width = 300
                        // }
                    }
                    return {
                        Header: header,
                        accessor: tKey,
                        width: width,
                        sticky: sticky ? "left" : "",
                        textColor: textColor,
                        className: 'text-truncate text-right'
                    }
                })
                
                setState(prevState => ({ ...prevState, trendTable, filteredArray: trendTable, columns, clusterHeaders }))
            } else {
                const columns = []	
                setState(prevState => ({ ...prevState, trendTable, filteredArray: trendTable, columns }))
            }
        }
    }, [state.responseDetails])

    const downloadPDF = async (state) => {
        const chartContainer1 = document.getElementById('chartContainer1');
        const chartContainer2 = document.getElementById('chartContainer2');
        const chartContainer3 = document.getElementById('chartContainer3');
        // const table = document.getElementById('table');
        const table = tableRef.current;
    
        if (!chartContainer1) {
          console.error("Chart container not found.");
          return;
        }
    
        try {
            const canvas1 = await html2canvas(chartContainer1);
            const canvas2 = await html2canvas(chartContainer2);
            const canvas3 = chartContainer3 ? await html2canvas(chartContainer3) : ''
            
            const tableData = await html2canvas(table)
            // const pdf = new jsPDF();
            const pdf = new jsPDF('p', 'mm', 'a4');
            
            let selectedPeriod = 'for the period off '+ (momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY HH:mm')+ ' '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY HH:mm'))
            
            let account = state.selectedAccount && state.selectedAccount.length ? 'with the account '+ (state.selectedAccount.join(", ")) : ''
            let region = state.selectedRegion && state.selectedRegion.length ? 'in the region '+ (state.selectedRegion.join(", ")) : ''
            let cluster = state.selectedClusters ? state.selectedClusters : ''
            if(state.selectedClusters) {
                cluster = 'in the cluster '+ state.selectedClusters
            }
            const textBoxWidth = 150;
            // const textBoxHeight = 50;
            const fontSize = 14;
            const lineHeight = 8;

            let longText = `Showing ${props.selectedReport && props.selectedReport.report_id ? props.selectedReport.report_id : ''} report obtained from the ${props.selectedProvider} ${account} ${region} ${cluster} ${selectedPeriod}`

            let words = longText.split(' ');
            let text = '';
            let y = 8; // Starting y-coordinate for text
            for (const word of words) {
                const textWithWord = text + word + ' ';
                const textWidth = pdf.getStringUnitWidth(textWithWord) * fontSize / pdf.internal.scaleFactor;
                if (textWidth > textBoxWidth) {
                    pdf.text(text, 10, y); // Draw the text
                    text = word + ' '; // Start a new line
                    y += lineHeight; // Move to the next line
                } else {
                    text = textWithWord; // Continue current line
                }
            }

            // Draw the remaining text
            pdf.text(text, 10, y); 
            // pdf.text(`${selectedPeriod} report for the user ${item.userName}`, 10, 10) 
            // pdf.text(`spanning from ${momentDateGivenFormat(props.startDate, 'DD MMM YYYY')} to ${momentDateGivenFormat(props.endDate, 'DD MMM YYYY')}`, 10, 20)
            // pdf.text(`Report for User - ${item.userName}`, 15, 10);
            // pdf.text(`Period from - ${momentConvertionUtcToLocalTime(props.startDate, 'DD MMM YYYY') + ' to '+momentConvertionUtcToLocalTime(props.endDate, 'DD MMM YYYY') }`, 15, 10);
            // pdf.text(`Frequency from - ${momentConvertionUtcToLocalTime(props.startDate, 'DD MMM YYYY') + ' to '+momentConvertionUtcToLocalTime(props.endDate, 'DD MMM YYYY') }`, 15, 10);
            let startHeight = y+8
            if(canvas3) {
                pdf.addImage(canvas1.toDataURL('image/png'), 'PNG', 5, startHeight, 65, 60);
                pdf.addImage(canvas2.toDataURL('image/png'), 'PNG', 75, startHeight, 65, 60);
                pdf.addImage(canvas3.toDataURL('image/png'), 'PNG', 140, startHeight, 65, 60);
                // pdf.addImage(tableData.toDataURL('image/png'), 'PNG', 5, 100, 200, 80);
            } else {
                pdf.addImage(canvas1.toDataURL('image/png'), 'PNG', 5, startHeight, 100, 60);
                pdf.addImage(canvas2.toDataURL('image/png'), 'PNG', 110, startHeight, 100, 60);
                // pdf.addImage(tableData.toDataURL('image/png'), 'PNG', 5, 100, 200, 80);
            }

            const pageHeight = 295; // A4 page height in mm
            const pageWidth = 210; // A4 page width in mm
            const imgHeight = (100 + tableData.height) * pageWidth / tableData.width;
            let heightLeft = imgHeight;
        
            const pdfPageCount = Math.ceil(heightLeft / pageHeight);
            
            for (let page = 1; page <= pdfPageCount; page++) {
                // Get the height of the content for the current page
                const yStart = (page - 1) * pageHeight;
                const height = Math.min(heightLeft, pageHeight);
                heightLeft -= height;
          
                // Add a new page to the PDF
                pdf.addPage();
          
                // Draw the content on the current page
                pdf.addImage(tableData, 'image/png', 0, yStart, pageWidth, height, undefined, 'FAST');
            }

            // html2canvas(table).then(tab => {
            //     const imgData = tab.toDataURL('image/png');
            //     const pdf = new jsPDF('p', 'mm', 'a4');
            //     pdf.addImage(imgData, 'PNG', 10, 10, 190, 100);
            // });
        
            const timestamp = new Date().getTime();
            pdf.save(`report_${timestamp}.pdf`);

            setState(prevState => ({ ...prevState, showDownloadLoading: false }))

        } catch (error) {
          console.error("Error capturing charts:", error);
          setState(prevState => ({ ...prevState, showDownloadLoading: false }))
        }
    }

	const handleChildClick = (event, type, dropdownType, section) => {	
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            props.closeSidePanel()
        }
        
		if (durationRef.current && !durationRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            {/* <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div> */}
            <div className='search w-4/5 bg-white overflow-auto' ref={clickOut}>
                <div className='bg-white flex justify-between p-2'>
                    <div className='text-black flex flex-wrap'>
                        <p className='text-xl'>Reports</p>
                        <p className='ml-4 mb-0 self-center text-info'>Id: <span className='text-black'>{props.selectedReport && props.selectedReport.report_id ? props.selectedReport.report_id : ''}</span></p>
                        <p className='ml-4 mb-0 self-center text-info'>Description: <span className='text-black'>{props.selectedReport && props.selectedReport.title ? props.selectedReport.title : ''}</span></p>
                    </div>
                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-black cursor-pointer' width="25" height="25" onClick={() => props.closeSidePanel()} />
                    </div>
                </div>
                <div className={`px-2`}>
                    {/* {state.showLoading ?
                        <div className='flex justify-center m-4'>
                            <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="currentColor" viewBox="0 0 24 24">
                                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                <path d="M4 12a8 8 0 0112-6.9" />
                            </svg>
                        </div>
                    : */}
                        <React.Fragment>
                        <div className='flex flex-wrap'>
                            <div className='flex justify-between col-md-12 '>
                                <p className='flex flex-wrap self-center mb-2'>
                                    <span className='self-end'>Showing report</span>
                                    {props && props.selectedProvider ?
                                        <React.Fragment>
                                        <span className='ml-1 self-end'>obtained from the</span>
                                        <span className='ml-1 self-end text-info'>{props.selectedProvider.toUpperCase()}</span>
                                        <span className='ml-1 self-end'>provider</span>
                                        </React.Fragment>
                                    : null}
                                    <React.Fragment>
                                    <span className='ml-1 self-end mr-2'>with the account</span>
                                    <div className='self-center mt-1' style={{minWidth: '150px', maxWidth: '150px'}} onClick={(event) => {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'muliselectDropDown', 'isAccountOpen')
                                    }}>
                                        <p className={`flex bg-transparent border-b border-lightGray py-1 justify-between px-2 truncate ${state.selectedAccount && state.selectedAccount.length ? 'text-black' : 'text-lightGray'}`}>
                                            {state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.selectedAccount && state.selectedAccount.length ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.isAccountOpen ?
                                            <MultiSelectSection 
                                                fields={["account_id", "account_name"]}
                                                options={propProjAccounts[props.selectedProvider] ? propProjAccounts[props.selectedProvider] : []}
                                                widthClass={'minWidth220'}
                                                selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedAccount: value, callClusterList: true }))}
                                                removeTopOptions={true}
                                            />
                                        : null}
                                    </div>
                                    
                                    <span className='ml-1 self-end mr-2'>in the</span>
                                    <div className='self-center mt-1' style={{minWidth: '150px', maxWidth: '150px'}} onClick={(event) => {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'muliselectDropDown', 'isRegionOpen')
                                    }}>
                                        <p className={`flex bg-transparent border-b border-lightGray py-1 justify-between px-2 truncate ${state.selectedRegion && state.selectedRegion.length ? 'text-black' : 'text-lightGray'}`}>
                                            {state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.selectedRegion && state.selectedRegion.length ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.isRegionOpen ?
                                            <MultiSelectSection 
                                                fields={["region", "name"]}
                                                options={propProjRegions[props.selectedProvider] ? propProjRegions[props.selectedProvider] : []}
                                                widthClass={'minWidth220'}
                                                selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value, callClusterList: true }))}
                                                removeTopOptions={true}
                                            />
                                        : null}
                                    </div>
                                    <span className='ml-1 self-end mr-2'>region</span>
                                    <span className='ml-1 self-end mr-2'>in the</span>
                                    <div className='self-center mt-1' style={{minWidth: '150px', maxWidth: '150px'}} onClick={(event) => {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', 'isClusterOpen')
                                    }}>
                                        <p className={`flex bg-transparent border-b border-lightGray py-1 justify-between px-2 truncate ${state.selectedClusters ? 'text-black' : 'text-lightGray'}`}>
                                            {state.selectedClusters ? state.selectedClusters : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.selectedClusters ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.isClusterOpen ?
                                            <MultiSelectSection 
                                                options={state.clusters}
                                                widthClass={'minWidth220'}
                                                selectedValues={state.selectedClusters}
                                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedClusters: value }))}
                                                //callHpcReports: props.selectedReport.category && props.selectedReport.category.includes('Cluster') ? true : false
                                                singleSelection={true}
                                                removeTopOptions={true}
                                            />
                                        : null}
                                    </div>
                                    <span className='ml-1 self-end mr-2'> cluster</span>
                                    {props.selectedReport.category && props.selectedReport.category.includes('Queue') ? 
                                        <React.Fragment>
                                        <span className='ml-1 self-end mr-2'>for the</span>
                                        <div className='self-center mt-1' style={{minWidth: '150px', maxWidth: '150px'}} onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', 'isQueueOpen')
                                        }}>
                                            <p className={`flex bg-transparent border-b border-lightGray py-1 justify-between px-2 truncate ${state.selectedQueue ? 'text-black' : 'text-lightGray'}`}>
                                                {state.selectedQueue ? state.selectedQueue : 'Select'}
                                                <Icon icon="icon-park-solid:down-one" className={`${state.selectedQueue ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                            </p>
                                            {state.isQueueOpen ?
                                                <MultiSelectSection 
                                                    options={state.queues}
                                                    widthClass={'minWidth220'}
                                                    selectedValues={state.selectedQueue}
                                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedQueue: value }))}
                                                    //callHpcReports: true 
                                                    singleSelection={true}
                                                    removeTopOptions={true}
                                                />
                                            : null}
                                        </div>
                                        <span className='ml-1 self-end mr-2'> queue</span>
                                        </React.Fragment>
                                    : null}
                                    {props.selectedReport && props.selectedReport.report_type && props.selectedReport.report_type === 'spendboard' && (props.selectedReport.category && props.selectedReport.category.includes('User')) ?
                                        <React.Fragment>
                                        <span className='ml-1 self-end mr-2'> by the user</span>
                                        
                                        <div className='self-center mt-1' style={{minWidth: '150px', maxWidth: '150px'}} onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', 'isUserOpen')
                                        }}>
                                            <p className={`flex bg-transparent border-b border-lightGray py-1 justify-between px-2 truncate ${state.selectedUser ? 'text-black' : 'text-lightGray'}`}>
                                                {state.selectedUser ? state.selectedUser : 'Select'}
                                                <Icon icon="icon-park-solid:down-one" className={`${state.selectedUser ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                            </p>
                                            {state.isUserOpen ?
                                                <MultiSelectSection 
                                                    fields={["userName", "userName"]}
                                                    options={state.users}
                                                    widthClass={'minWidth220'}
                                                    selectedValues={state.selectedUser ? state.selectedUser : []}
                                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedUser: value }))}
                                                    singleSelection={true}
                                                    removeTopOptions={true}
                                                />
                                            : null}
                                        </div>
                                        </React.Fragment>
                                    : null}
                                    <span className='ml-1 self-end mr-2'>for the duration</span>
                                    <div className='self-center mt-1' style={{minWidth: '200px', maxWidth: '200px'}} onClick={(event) => {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', 'isDurationOpen')
                                    }}>
                                        <p className={`flex bg-transparent border-b border-lightGray py-1 justify-between px-2 truncate ${state.datePickerStartDate ? 'text-black' : 'text-lightGray'}`}>
                                            {state.datePickerStartDate ?
                                                <span> {momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY')}</span>
                                            : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.datePickerStartDate ? "text-black" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.isDurationOpen ?
                                            <MultiSelectSection
                                                fields={["value", "label"]}
                                                options={COST_DURATION_OPTIONS}
                                                widthClass={'minWidth220'}
                                                selectedValues={state.selectedDuration ? state.selectedDuration : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedDuration: value }))
                                                }}
                                                singleSelection={true}
                                                removeTopOptions={true}
                                            />
                                        : null}
                                    </div>
                                    <p className="mb-0 ml-4 text-info mt-1 self-center cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, searchInput: true, callHpcReports: true, showLoading: true }))}>Search</p>

                                    </React.Fragment>
                                </p>
                                <p className='text-info mb-1 ml-3 cursor-pointer self-center' onClick={() => {
                                    if(!state.showDownloadLoading) {
                                        setState(prevState => ({ ...prevState, showDownloadLoading: true })) 
                                        downloadPDF(state)
                                    }
                                }}>
                                    {state.showDownloadLoading ?
                                        <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="currentColor" viewBox="0 0 24 24">
                                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                            <path d="M4 12a8 8 0 0112-6.9" />
                                        </svg>
                                    : null}    
                                    Download&nbsp;PDF
                                    
                                </p>
                            </div>
                            {state.selectedUserDetails && state.selectedUserDetails.user_name ?
                                <div className='w-full flex justify-end mb-1'>
                                    <p className={`btn bg-lightGray px-2 py-1 cursor-pointer rounded-md text-black mr-3 mb-0`} onClick={() => setState(prevState => ({ ...prevState, selectedUserDetails: {}, callHpcReports: true, showLoading: true, graphData1: {}, graphData2: {}, graphData3: {} }))}>Back</p>
                                </div>
                            : null}
                            <div className={`lg:w-1/3 md:w-1/2 w-full`} id={'chartContainer1'}>
                                <div className='bg-GhostWhite p-3 rounded-md'>
                                    {/* <span className='mt-1 mb-0 text-xs text-lightGray'>{state.graphData1.description ? state.graphData1.description :''}</span>  */}
                                    {state.budgetSpendData && Object.entries(state.budgetSpendData).length ?
                                        <div className='-mt-2 -mb-2 mr-4 self-center'>
                                            <ApexBarChart
                                                graphData={state.budgetSpendData}
                                                sparkline={false}
                                                yaxis={true}
                                                yaxisLabel={true}
                                                xaxis={false}
                                                xaxisFormat={'string'}
                                                xaxisLabel={false}
                                                axisLabelColor={'#666666'}
                                                paddingLeft={0}
                                                legend={false}
                                                legendPostion={'bottom'}
                                                legendHorizontalAlign={'center'}
                                                legendUseSeriesColor={true}
                                                stacked={false}
                                                height={200}
                                                horizontal={true}
                                                barHeight={'40%'}
                                                barEndShape={'rounded'}
                                                columnWidth={'25%'}
                                                gradient={false}
                                                gradientColor={['#009fdf', '#CD5A9F']}
                                                hideTooltipValue={true}
                                                backgroundBarShape={'rounded'}
                                                backgroundBarColors={['#333947']}
                                                showBackgroundBarColors={false}
                                                className={'transparentTooltip'}
                                                dataLabels= {true}
                                                dataLabelsTextAnchor = {'end'}
                                                dataLabelPosition={'top'}
                                                colors={['#43b02a', '#009FDF']}
                                                dataLabelsOffsetX={0}
                                                chartDistributed={true}
                                            />
                                            <p className='flex justify-center self-center text-red'>
                                                <span className='ml-1 text-md self-center'>{state.budgetSpendTrendData && Math.round(100 - (state.budgetSpendTrendData.spend_total/state.budgetSpendTrendData.budget_total)*100) > 0 ? 'Underutilized' : 'Overutilized'}</span>
                                                {state.budgetSpendTrendData && Math.round(100 - (state.budgetSpendTrendData.spend_total/state.budgetSpendTrendData.budget_total)*100) > 0 ?
                                                    <span className='ml-1 text-md self-center'>{state.budgetSpendTrendData && Math.round(100 - (state.budgetSpendTrendData.spend_total/state.budgetSpendTrendData.budget_total)*100)}%</span>
                                                : 
                                                    <span className='ml-1 text-md self-center'>{state.budgetSpendTrendData && Math.round((state.budgetSpendTrendData.spend_total/state.budgetSpendTrendData.budget_total)*100)-100}%</span>
                                                }
                                            </p>
                                        </div>
                                    : null}
                                </div>
                            </div>

                            <div className={`lg:w-1/3 md:w-1/2 w-full lg:pl-2 md:pl-2 pl-0`} id={'chartContainer2'}>
                                <div className='bg-GhostWhite rounded-md p-3 h-100'>
                                    {/* <span className='mt-1 mb-0 small text-lightGray'>{state.graphData2.description ? state.graphData2.description :''}</span> */}
                                    {state.budgetSpendBarLineGraphData && Object.entries(state.budgetSpendBarLineGraphData).length ?
                                        <div className='mb-n3'>
                                            <ApexMixedBarLineChart
                                                graphData={state.budgetSpendBarLineGraphData}
                                                sparkline={false}
                                                yaxis={false}
                                                yaxisLabel={false}
                                                xaxis={true}
                                                xaxisFormat={'datetime'}
                                                xaxisLabel={true}
                                                axisLabelColor={'#666666'}
                                                paddingLeft={-25}
                                                legend={true}
                                                legendPostion={'bottom'}
                                                legendHorizontalAlign={'center'}
                                                legendUseSeriesColor={true}
                                                stacked={false}
                                                height={200}
                                                horizontal={false}
                                                barHeight={'40%'}
                                                barEndShape={'flat'}
                                                columnWidth={'25%'}
                                                gradient={true}
                                                gradientColor={['#039BE5', '#5F5BA2']}
                                                hideTooltipValue={true}
                                                backgroundBarShape={'flat'}
                                                backgroundBarColors={['#333947']}
                                                showBackgroundBarColors={false}
                                                className={'transparentTooltip'}
                                                colors={['#43b02a', '#009FDF']}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>

                            <div className={`lg:w-1/3 md:w-1/2 w-full lg:pl-2 md:pl-2 pl-0 lg:mt-0 md:mt-0 mt-3`} id={'chartContainer3'}>
                                <div className='bg-GhostWhite rounded-md p-3 h-100'>
                                    {/* <small className='mt-1 mb-0 small text-lightGray'>{state.graphData3.description ? state.graphData3.description :''}</small> */}
                                    {state.spendStackedTrendData && Object.entries(state.spendStackedTrendData).length ?
                                    <React.Fragment>
                                    <div className='-mt-3 -mb-3 mr-2'>
                                        <ApexStackedBarChart
                                            graphData={state.spendStackedTrendData}
                                            sparkline={false}
                                            yaxis={false}
                                            yaxisLabel={true}
                                            xaxis={true}
                                            xaxisFormat={'datetime'}
                                            xaxisLabel={true}
                                            axisLabelColor={'#666666'}
                                            paddingLeft={-25}
                                            legend={true}
                                            stacked={true}
                                            height={225}
                                            horizontal={false}
                                            // barHeight={'40%'}
                                            barEndShape={'flat'}
                                            columnWidth={'25%'}
                                            gradient={true}
                                            stroke={'hide'}
                                            // colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']} //['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']
                                            // colors={['#FFCD00', '#ff8200', '#0057b8', '#8e3a80']}
                                            colors={['#d13737', '#1261AB', '#6500B7', '#27B224', '#037a2b', '#ba0000', '#d13737', '#ffb534' ]}
                                            hideTooltipValue={true}
                                            backgroundBarShape={'flat'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={false}
                                            className={'transparentTooltip'}
                                        />
                                    </div>
                                    {/* <ul className="w-1/4 self-center mb-0 mt-3 text-black">
                                        {state["trendBreakDownTotal_" + (state.startRecord + index)] && Object.values(state["trendBreakDownTotal_" + (state.startRecord + index)]).map((value, tIndex) => {
                                            return(
                                                <li key={'trend_' + tIndex} className='flex mb-2'><span className={`legend-circle bg-${state.trendBreakDownColor[tIndex]}`}></span> <span className="pt-1 ml-2 text-gray-400 d-inline-block"> &nbsp;{thousandSeparator(value)}</span></li>
                                            )
                                        })}
                                    </ul> */}
                                    </React.Fragment>
                                    : null}
                                </div>
                            </div>
                        </div>

                        {state.trendTable ?
                            <div className="w-full mt-3">
                                <div className="flex justify-between pt-2 mb-1">
                                    <p className="mb-0 self-center">Showing {state.filteredArray && state.filteredArray.length} of total {state.trendTable && state.trendTable.length} Cost and usage breakdown</p>
                                    <div className='flex'>
                                        {state.trendTable && state.trendTable.length ?
                                            <div className="flex">
                                                <CSVLink
                                                    data={state.filteredArray ? state.filteredArray : []} 
                                                    headers={state.clusterHeaders ? state.clusterHeaders : []}
                                                    filename={'spenboard-clusters-'+new Date().getTime()+'.csv'}
                                                    className={"mt-2"}
                                                    target="_blank"
                                                >
                                                    <Icon icon="material-symbols:download" className='text-lightGray self-center' width="22" height="22"/>
                                                </CSVLink>
                                            </div>
                                        : null}
                                        <div className="border-b border-lightGray mx-2 w-full flex">
                                            {/* <small className=''>Show </small> */}
                                            <select className="form-control w-full bg-transparent text-black truncate"
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        selected_Period: selectedValue,
                                                        showLoading: false
                                                    }));
                                                    // onChangePeriod(selectedValue, (state.startRecord + index), item);
                                                    // if(state['showExpandedSectoin_'+(state.startRecord + index)]) {
                                                    //     getExpandedItemDetails(selectedValue, item, (state.startRecord + index));
                                                    // }

                                                    // Use the selectedValue directly in the callback to avoid potential issues
                                                }}
                                                value={state.selected_Period}
                                            >
                                                {state.periodOptions && state.periodOptions.map((item, itemIndex) => {
                                                    return(
                                                        <option key={'opt_'+itemIndex} className='text-black' value={item.value}>{item.label}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                {state['columns'] && state['columns'].length ? 
                                    <div className="overflow-x-scroll">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr className='border-b border-extraLightGray'>
                                                    {state['columns'].map((col, colIndex) => {
                                                        return(
                                                            <th scope="col" key={'col_'+colIndex} className={`${col.sticky ? 'sticky-col' : 'bg-extraLightGray text-black'} ${colIndex === 1 ? 'sticky-col-1' : ''} px-6 py-1 text-left text-sm font-medium uppercase tracking-wider`} style={{minWidth: col.width+'px'}}>{col.Header}</th>
                                                        )
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {state.filteredArray && state.filteredArray.length ?
                                                    state.filteredArray.map((col, colIndex) => {
                                                        return(
                                                            <tr className='border-b border-extraLightGray' key={'colval_'+colIndex}>
                                                                {state['columns'].map((obj, objIndex) => {
                                                                    return(
                                                                        <td key={objIndex+'_'+colIndex} className={`${objIndex <= 1 ? 'sticky-col' : 'bg-GhostWhite text-black'} ${objIndex === 1 ? 'sticky-col-1' : ''} px-6 py-1 whitespace-nowrap`}>{col[obj.accessor]}</td>
                                                                    )
                                                                })}
                                                            </tr>
                                                        )
                                                    })
                                                : null}
                                            </tbody>
                                        </table>
                                    </div>
                                : null}
                            </div>
                        : null}
                                    
                        <div className='flex flex-wrap'>
                            <div className='bg-white rounded-md mt-2 w-full'>
                                {/* <p className='text-black mb-1 pl-3'>{state.tableDetails && state.tableDetails.description ? state.tableDetails.description : ''}</p> */}
                                <div className='w-full px-3 py-1 mb-4 overflow-auto' ref={tableRef} id={'table'}>
                                    <table className="min-w-full table-auto">
                                        <thead className='text-md text-black bg-extraLightGray'>
                                            <tr>
                                                {state.tableDetails && state.tableDetails.fields ?
                                                    state.tableDetails.fields.map(head => {
                                                        return(
                                                            <th key={"head_"+head} className='text-sm py-2 text-start pl-2'>{head}</th>
                                                        )
                                                    })
                                                
                                                : null}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {state.tableDetails && state.tableDetails.table_data ?
                                                state.tableDetails.table_data.map((row, index)=> {
                                                    return(
                                                        <tr key={"head1_"+index} className={`${!index ? '' : 'border-t border-extraLightGray'} ${index % 2 === 0 ? 'bg-GhostWhite' : ''}`} onClick={() => {
                                                            if(row.user_name) {
                                                                setState(prevState => ({ ...prevState, selectedUserDetails: row, callHpcReports: true, showLoading: true, graphData1: {}, graphData2: {}, graphData3: {} }))
                                                            }
                                                        }}>
                                                            {state.tableDetails && state.tableDetails.fields ?
                                                                state.tableDetails.fields.map((head, hIndex) => {
                                                                    return(
                                                                        <td key={"head2_"+hIndex} className={`${head === 'User Name' ?'text-info cursor-pointer' : 'text-black'} p-2 text-md ${!hIndex ? '' : ''}`}>
                                                                            {head === 'status1' || head === 'Status1' ?
                                                                                <span className='border border-info px-2 py-1 rounded-full'>{row[head]}</span>
                                                                            :
                                                                                row[head]
                                                                            }</td>
                                                                    )
                                                                })
                                                            : null}
                                                        </tr>
                                                    )

                                                })
                                            : null}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        </React.Fragment>
                    {/* } */}
                </div>
            </div>
        </div>
    
    )
}
export default CostReportSection;
    